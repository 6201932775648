import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';

const getStatusStyle = (status) => {
  switch(status) {
    case 'Not Started':
      return 'bg-red-100 text-red-600';
    case 'Sent':
      return 'bg-green-100 text-green-600';
    case 'Read':
      return 'bg-yellow-100 text-yellow-600';
    case 'Waiting to Review':
      return 'bg-blue-100 text-blue-600';
    case 'Replied':
      return 'bg-orange-100 text-orange-600'
    default:
      return 'bg-transparent text-black';
  }
};

const PromptBox = ({ isVisible, promptText, setPromptText, handleAutoSend, position, promptBoxRef }) => {
  // NOTE: Adjust these values to change the position of the prompt box
  const offsetX = -220; // Positive values move the box right, negative values move it left
  const offsetY = 10; // Positive values move the box down, negative values move it up

  return (
    <div 
      ref={promptBoxRef}
      className={`fixed bg-white p-4 rounded-lg shadow-lg w-80 z-50 transition-all duration-300 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
      }`}
      style={{
        top: `${Math.min(position.top + position.height + offsetY, window.innerHeight - 300)}px`,
        left: `${Math.min(Math.max(position.left + offsetX, 20), window.innerWidth - 340)}px`,
        maxWidth: 'calc(100vw - 40px)',
        maxHeight: 'calc(100vh - 40px)',
      }}
    >
      <textarea
        className="w-full h-32 p-2 mb-4 bg-gray-100 rounded-md resize-none"
        value={promptText}
        onChange={(e) => setPromptText(e.target.value)}
        placeholder="Enter your prompt here..."
      />
      <div className="flex justify-center">
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors duration-200"
          onClick={handleAutoSend}
        >
          Auto Send
        </button>
      </div>
    </div>
  );
};

const EmailStatus = () => {
  const [dummyData, setDummyData] = useState([
    { id: 1, company: 'Andrew', industry: 'Automotive', email: 'andrew.orientdn@gmail.com', content: '', status: 'Not Started' },
    { id: 2, company: 'Septimus', industry: 'Finance', email: 'info@septimus.com', content: '', status: 'Waiting to Review' },
    { id: 3, company: 'Calzoni', industry: 'Food', email: 'support@calzoni.com', content: '', status: 'Read' },
    { id: 4, company: 'Baptista', industry: 'Healthcare', email: 'info@baptista.com', content: '', status: 'Replied' },
    { id: 5, company: 'Stanton', industry: 'Education', email: 'contact@stanton.edu', content: '', status: 'Sent' },
    { id: 6, company: 'Vetrovs', industry: 'Automotive', email: 'sales@vetrovs.com', content: '', status: 'Not Started' },
    { id: 7, company: 'TechGiant', industry: 'Technology', email: 'info@techgiant.com', content: '', status: 'Not Started' },
    { id: 8, company: 'FinanceWiz', industry: 'Finance', email: 'support@financewiz.com', content: '', status: 'Not Started' },
    { id: 9, company: 'FoodDelights', industry: 'Food', email: 'contact@fooddelights.com', content: '', status: 'Not Started' },
    { id: 10, company: 'HealthPlus', industry: 'Healthcare', email: 'info@healthplus.com', content: '', status: 'Not Started' },
    { id: 11, company: 'EduSmart', industry: 'Education', email: 'admin@edusmart.com', content: '', status: 'Not Started' },
    { id: 12, company: 'AutoInnovate', industry: 'Automotive', email: 'sales@autoinnovate.com', content: '', status: 'Not Started' },
    { id: 13, company: 'TechSolutions', industry: 'Technology', email: 'support@techsolutions.com', content: '', status: 'Not Started' },
    { id: 14, company: 'MoneyMatters', industry: 'Finance', email: 'info@moneymatters.com', content: '', status: 'Not Started' },
    { id: 15, company: 'GourmetEats', industry: 'Food', email: 'orders@gourmeteats.com', content: '', status: 'Not Started' },
    { id: 16, company: 'MediCare', industry: 'Healthcare', email: 'patients@medicare.com', content: '', status: 'Not Started' },
    { id: 17, company: 'LearnQuest', industry: 'Education', email: 'students@learnquest.com', content: '', status: 'Not Started' },
    { id: 18, company: 'DriveMax', industry: 'Automotive', email: 'service@drivemax.com', content: '', status: 'Not Started' },
    { id: 19, company: 'DataDynamics', industry: 'Technology', email: 'info@datadynamics.com', content: '', status: 'Not Started' },
    { id: 20, company: 'WealthWise', industry: 'Finance', email: 'clients@wealthwise.com', content: '', status: 'Not Started' },
  ]);

  const senderConfig = {
    email: "user1@example.com",
    name: localStorage.getItem("nickname")
  };

  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const [showPromptBox, setShowPromptBox] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [promptButtonPosition, setPromptButtonPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const promptButtonRef = useRef(null);
  const promptBoxRef = useRef(null);
  const [campaignState, setCampaignState] = useState(null);
  const sendmail_api = process.env.REACT_APP_SENDMAIL_API

  useEffect(() => {
    const storedCampaignState = localStorage.getItem('campaignState');
    setCampaignState(storedCampaignState);

    if (storedCampaignState === 'Confirm') {
      handleConfirmState();
    } else if (storedCampaignState === 'Cancel') {
      handleCancelState();
    }

    const updatePromptButtonPosition = () => {
      if (promptButtonRef.current) {
        const rect = promptButtonRef.current.getBoundingClientRect();
        setPromptButtonPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          height: rect.height
        });
      }
    };

    const handleClickOutside = (event) => {
      if (showPromptBox && promptBoxRef.current && !promptBoxRef.current.contains(event.target) && event.target !== promptButtonRef.current) {
        setShowPromptBox(false);
      }
    };

    updatePromptButtonPosition();
    window.addEventListener('resize', updatePromptButtonPosition);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', updatePromptButtonPosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPromptBox]);

  const handleConfirmState = () => {
    autocreateEmail();
    autoSend();
  };

  const handleCancelState = () => {
    const updatedData = dummyData.map(row => ({
      ...row,
      content: '',
      status: 'Not Started'
    }));
    setDummyData(updatedData);
  };

  const autocreateEmail = () => {
    // Logic for creating email will be declared later
    console.log("Creating email...");
    // For now, let's update the content of all rows
    const updatedData = dummyData.map(row => ({
      ...row,
      content: 'A sample email',
      // status: 'Waiting to Review'
    }));
    setDummyData(updatedData);
  };

  const autoSend = () => {
    // Logic for auto sending email will be declared later
    console.log("Auto sending email...");
    // For now, let's update the status of all rows to 'Sent'
    const updatedData = dummyData.map(row => ({
      ...row,
      status: 'Sent'
    }));
    setDummyData(updatedData);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const newSelectedRows = {};
    dummyData.forEach(row => {
      newSelectedRows[row.id] = isChecked;
    });
    setSelectedRows(newSelectedRows);
  };

  const handleRowSelect = (id) => {
    const newSelectedRows = { ...selectedRows, [id]: !selectedRows[id] };
    setSelectedRows(newSelectedRows);
    setSelectAll(Object.values(newSelectedRows).every(Boolean) && Object.keys(newSelectedRows).length === dummyData.length);
  };

  const handlePromptClick = () => {
    setShowPromptBox(!showPromptBox);
  };

  const handleAutoSend = () => {
    console.log("Auto Send clicked. Prompt text:", promptText);
    // Here you would typically save the promptText to your state or send it to an API
    setShowPromptBox(false);
    // Reset the prompt text after sending
    setPromptText('');
  };

  const handleMailGenerator = () => {
    navigate("/mail-reply")
  }

  // New function to handle status click
  const handleStatusClick = (status, id) => {
    if (status === 'Replied') {
      navigate('/mail-reply', { state: { id } });
    }
  };
  // New functions to handle button clicks
  const handleMailboxClick = () => {
    navigate("/mailbox"); // Replace with the actual path to your Mailbox page
  };

  const handleCampaignClick = () => {
    navigate("/campaign"); // Replace with the actual path to your Campaign page
  };

  const handleDeleteClick = () => {
    const newData = dummyData.filter(row => !selectedRows[row.id]);
    setDummyData(newData);
    setSelectedRows({});
    setSelectAll(false);
  };

  const handleGenerate = (id) => {
    if (campaignState === 'Cancel') {
      const updatedData = dummyData.map(row => 
        row.id === id ? { ...row, content: 'A Sample Mail', status: 'Waiting to Review' } : row
      );
      setDummyData(updatedData);
    }
  };

  const sendEmail = async (rowData) => {


    try {
      const response = await fetch(sendmail_api, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email_from: senderConfig.email,
          name_from: senderConfig.name,
          email_to: rowData.email,
          name_to: rowData.company,
          subject: "Your email flight plan!",
          content: rowData.content,
          html_content: '',
        })
      });

      if (!response.ok) {
        console.log('Failed to send email');
        console.log(response.json())
      } else {
        console.log('Email sending successful');
      }

      // return await response.json();
    } catch (error) {
      console.log('Email sending error:', error);
    }
  };

  const handleSend = (id) => {
    if (campaignState === 'Cancel') {
      // Find the specific row data
      const rowData = dummyData.find(row => row.id === id);
      // Check if content is empty
      if (!rowData.content.trim()) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Content',
          text: 'Please add content before sending the email',
          confirmButtonColor: '#3085d6'
        });
        return;
      }
      try{
        sendEmail(rowData);

        // Update the status to 'Sent' on success
        const updatedData = dummyData.map(row =>
          row.id === id ? { ...row, status: 'Sent' } : row
        );
        setDummyData(updatedData);
      } catch (error) {
        // Update the status to 'Failed to Send' on error
        const updatedData = dummyData.map(row =>
          row.id === id ? { ...row, status: 'Failed to Send' } : row
        );
        setDummyData(updatedData);


      


      // const updatedData = dummyData.map(row => 
      //   row.id === id ? { ...row, status: 'Sent' } : row
      // );
      // setDummyData(updatedData);
      }
    }
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      <HeaderMainPage />

      <div className="w-full max-w-[1500px] mx-auto mt-10 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-between items-center p-6 bg-gray-50">
          <h2 className="text-2xl font-bold text-gray-800">New Campaign</h2>
          <div className="space-x-2">
            <button onClick={handleMailboxClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Mail box</button>
            <button onClick={handleCampaignClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Campaigns</button>
            <button onClick={handleDeleteClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Delete</button>
            <button className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Config</button>
            <button 
              ref={promptButtonRef}
              className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200" 
              onClick={handlePromptClick}
            >
              Prompt
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="w-12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </th>
                  <th className="w-40 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Industry</th>
                  <th className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="w-90 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {dummyData.map(row => (
                  <tr key={row.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedRows[row.id] || false}
                        onChange={() => handleRowSelect(row.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 truncate max-w-[160px]" title={row.company}>
                        {row.company}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate max-w-[128px]" title={row.industry}>
                        {row.industry}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate max-w-[256px]" title={row.email}>
                        {row.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate max-w-[384px]" title={row.content}>
                        {row.content}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span 
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(row.status)} ${row.status === 'Replied' ? 'cursor-pointer' : ''}`}
                          onClick={() => handleStatusClick(row.status, row.id)}
                        >
                        <span className="w-2 h-2 rounded-full mr-2 mt-1.5" style={{backgroundColor: 'currentColor'}}></span>
                        {row.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button 
                        onClick={() => handleGenerate(row.id)}
                        className={`rounded-md w-[80px] h-[25px] mr-3 ${
                          campaignState === 'Confirm' ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-yellow-500 text-white hover:bg-yellow-600'
                        }`}
                        disabled={campaignState === 'Confirm'}
                      >
                        Generate
                      </button>
                      <button 
                        onClick={() => handleSend(row.id)}
                        className={`rounded-md w-[50px] h-[25px] ${
                          campaignState === 'Confirm' ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'
                        }`}
                        disabled={campaignState === 'Confirm'}
                      >
                        Send
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PromptBox
        isVisible={showPromptBox}
        promptText={promptText}
        setPromptText={setPromptText}
        handleAutoSend={handleAutoSend}
        position={promptButtonPosition}
        promptBoxRef={promptBoxRef}
      />
    </div>
  );
};

export default EmailStatus;
