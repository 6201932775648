// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './component/loginPage/Login';
import Signup from './component/loginPage/Signup';
import ForgotPassword from './component/loginPage/ForgotPassword';
import ResetPassword from './component/loginPage/ResetPassword';

import { AuthProvider } from './authorize/AuthContext';
import ProtectedRoute from './authorize/ProtectedRoute';

import LogoWhite from './assets/Logo_white.png';

import ClassifyCustomer from './component/onboardingPage/OnboardingSelect';
import OnboardingInputData from './component/onboardingPage/OnboardingUserData';
import CampaignInitialize from './component/onboardingPage/OnboardingSelectDataFill';
import CampManualFill from './component/onboardingPage/OnboardingCampManualFill';
import UserAIChatUI from './component/onboardingPage/OnboardingAIChat';
import OnboardingSelectPlan from './component/onboardingPage/OnboardingSelectPlan';
import OnboardingReviewCampaign from './component/onboardingPage/OnboardingReviewCampaign';
import OnboardingReviewUser from './component/onboardingPage/OnboardingReviewUser';

import Dashboard from './component/managingPage/Dashboard';
import MyCampaign from './component/managingPage/MyCampaign';
import NewCampaign from './component/managingPage/createCampaign';

import MailboxData from './component/mailPage/Mailbox';
import EmailStatus from './component/mailPage/emailStatus';
import MailReplyPage from './component/mailPage/MailReplyPage';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/login');
  };

  return (
    <div className="bg-white min-h-screen flex flex-col">
      <header style={{ width: '100%', height: '7%' }} className="bg-blue-800 shadow-md fixed top-0 left-0 right-0 flex justify-between items-center px-5 py-4">
        <img 
          src={LogoWhite} 
          alt="Brand Logo" 
          className="w-36 h-12 object-contain"
        />
        <button
          onClick={handleButtonClick}
          className="w-28 h-10 bg-blue-500 text-lg text-white font-semibold rounded-full cursor-pointer text-base hover:bg-blue-600 transition duration-300 ease-in-out"
        >
          Login
        </button>
      </header>
      <main className="flex-grow flex justify-center items-center mt-20">
        {/* Add your main content here */}
      </main>
    </div>
  )
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpw" element={<ForgotPassword />} />
          <Route path="/resetpw" element={<ResetPassword />} />

          <Route path="/onboarding-select" element={<ProtectedRoute><ClassifyCustomer /></ProtectedRoute>} />
          <Route path="/new-campaign" element={<ProtectedRoute><NewCampaign /></ProtectedRoute>} />
          
          <Route path="/campinitselect" element={<ProtectedRoute><CampaignInitialize /></ProtectedRoute>} />
          <Route path="/useraichat" element={<ProtectedRoute><UserAIChatUI /></ProtectedRoute>} />
          <Route path="/campfill" element={<ProtectedRoute><CampManualFill /></ProtectedRoute>} />
          <Route path="/onboarding-input" element={<ProtectedRoute><OnboardingInputData /></ProtectedRoute>} />
          <Route path="/onboarding-plan" element={<ProtectedRoute><OnboardingSelectPlan /></ProtectedRoute>} />
          <Route path="/onboardinguser" element={<ProtectedRoute><OnboardingReviewUser /></ProtectedRoute>} />
          <Route path="/reviewinfo" element={<ProtectedRoute><OnboardingReviewCampaign /></ProtectedRoute>} />

          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/my-campaign" element={<ProtectedRoute><MyCampaign /></ProtectedRoute>} />

          <Route path="/mailbox" element={<ProtectedRoute><MailboxData /></ProtectedRoute>} />
          <Route path="/email-status" element={<ProtectedRoute><EmailStatus /></ProtectedRoute>} />
          <Route path="/mail-reply" element={<ProtectedRoute><MailReplyPage /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;