import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import HeaderMainPage from '../../header/HeaderMainPage';
import campaignsData from '../../campaigns.json';
import IconMail from '../../assets/icon_mail.svg';
import IconCampaign from '../../assets/icon_campaign.svg';
import IconHeart from '../../assets/icon_heart.svg';

const Button = ({ onClick, className, text }) => (
  <button onClick={onClick} className={`bg-blue-800 ${className}`}>
    {text}
  </button>
);

// New functions to get statistics
const getEmailSentStats = () => {
  // This function will be implemented later to fetch real data
  return { sent: 1200, total: 2000 };
};

const getCampaignsStats = () => {
  // This function will be implemented later to fetch real data
  return 123;
};

const getSubscribersStats = () => {
  // This function will be implemented later to fetch real data
  return 50;
};

const StatisticPanel = () => {
  const navigate = useNavigate();
  const [emailStats, setEmailStats] = useState({ sent: 0, total: 0 });
  const [campaignsStats, setCampaignsStats] = useState(0);
  const [subscribersStats, setSubscribersStats] = useState(0);

  useEffect(() => {
    setEmailStats(getEmailSentStats());
    setCampaignsStats(getCampaignsStats());
    setSubscribersStats(getSubscribersStats());

    // Store campaign data in local storage
    localStorage.setItem('campaignData', JSON.stringify(campaignsData));
    console.log('Campaign data set in local storage:', campaignsData);
  }, []);

  const handleCreateNewCampaign = () => {
    console.log("Create New Campaign clicked")
    navigate('/onboarding-select');
    const keysToRemove = [
      'campaignDescription',
      'campaignMustHaveInfo',
      'campaignOutputFormat',
      'campaign_name',
      'companyType',
      'core_service',
      'ideal_client',
      'key_benefits',
      'linkedInResult',
      'linkedinUrl',
      'messagePairs',
      'nickname',
      'primary_goal_of_outreach_campaign',
      'problem_solved',
      'selectedPlan',
      'success_measurement',
      'targetIndustry',
      'target_audience',
      'unique_selling_proposition',
      'campaignData',
    ]
    // const keysToRemove = ['campaign_name', 'industry','target_audience','unique_selling_point','core_product','ideal_client','goal','campaignName','campaignDescription','campaignMustHaveInfo','campaignOutputFormat','campaignState'];

    keysToRemove.forEach(key => {
        localStorage.removeItem(key);
    });
  }; 

  return (
    <div className="px-4 md:px-20 lg:px-40 py-1 bg-grey">
      <div className="py-4 md:py-8 flex flex-col md:flex-row justify-between items-center mb-2">
        <div className="font-bold text-xl md:text-2xl mb-4 md:mb-0">Dashboard</div>
        <Button onClick={handleCreateNewCampaign} className="text-white px-4 py-2 rounded-full" text="+ Create New Campaign" />
      </div>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5 justify-center">
        <div className="flex items-center p-4 bg-white rounded-md shadow-md w-full md:w-1/3">
          <img src={IconMail} alt="Email Icon" className="w-14 h-14 mr-4 ml-5" />
          <div>
            <div className="text-sm">Email Sent</div>
            <div className="font-bold text-xl md:text-3xl">{`${emailStats.sent}/${emailStats.total}`}</div>
          </div>
        </div>

        <div className="flex items-center p-4 bg-white rounded-md shadow-md w-full md:w-1/3">
          <img src={IconCampaign} alt="Campaign Icon" className="w-14 h-14 mr-4 ml-5" />
          <div>
            <div className="text-sm">Campaigns</div>
            <div className="font-bold text-xl md:text-3xl">{campaignsStats}</div>
          </div>
        </div>

        <div className="flex items-center p-4 bg-white rounded-md shadow-md w-full md:w-1/3">
          <img src={IconHeart} alt="Subscribers Icon" className="w-14 h-14 mr-4 ml-5" />
          <div>
            <div className="text-sm">Subscribers</div>
            <div className="font-bold text-xl md:text-3xl">{subscribersStats}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DonutCharts = ({ sortBy }) => {
  const getChartData = (sort) => {
    // Dummy data based on sort type
    if (sort === 'date') {
      return {
        progress: [
          { name: 'Sent', value: 350 },
          { name: 'In Progress', value: 120 },
          { name: 'Not Started', value: 80 },
        ],
        status: [
          { name: 'Replied', value: 220 },
          { name: 'Read', value: 70 },
          { name: 'Waiting to Review', value: 160 },
        ]
      };
    } else {
      return {
        progress: [
          { name: 'Sent', value: 300 },
          { name: 'In Progress', value: 100 },
          { name: 'Not Started', value: 50 },
        ],
        status: [
          { name: 'Replied', value: 200 },
          { name: 'Read', value: 50 },
          { name: 'Waiting to Review', value: 150 },
        ]
      };
    }
  };

  const data = getChartData(sortBy);
  const ProgressColor = ['#6ceac0', '#ea6c6c', '#0da5e9'];
  const CampaignStatusColor = ['#00b503', '#e9001c', '#ffdb20'];

  return (
    <div className="py-5 md:py-10 flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8 bg-white p-4 rounded shadow-md">
      <div className="w-full md:w-1/2 h-[300px]">
        <h3 className="text-center font-bold mb-2">Progress</h3>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data.progress}
              cx="50%"
              cy="50%"
              outerRadius="80%"
              innerRadius="50%"
              fill="#8884d8"
              dataKey="value"
            >
              {data.progress.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={ProgressColor[index % ProgressColor.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full md:w-1/2 h-[300px]">
        <h3 className="text-center font-bold mb-2">Campaign Status</h3>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data.status}
              cx="50%"
              cy="50%"
              outerRadius="80%"
              innerRadius="50%"
              fill="#8884d8"
              dataKey="value"
            >
              {data.status.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CampaignStatusColor[index % CampaignStatusColor.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const SortByDropdown = ({ onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState('Date');

  const handleSort = (sort) => {
    setSelectedSort(sort);
    setIsOpen(false);
    onSortChange(sort.toLowerCase());
  };

  return (
    <div className="relative inline-block text-left w-40">
      <div>
        <button
          type="button"
          className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          Sortby
          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {['Date', 'Campaign name'].map((option) => (
              <a
                key={option}
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                role="menuitem"
                onClick={() => handleSort(option)}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ChartPanel = () => {
  const [sortBy, setSortBy] = useState('date');

  return (
    <div className="px-4 md:px-20 lg:px-40 bg-grey py-5">
      <div className="mb-4 flex justify-start">
        <SortByDropdown onSortChange={setSortBy} />
      </div>
      <DonutCharts sortBy={sortBy} />
    </div>
  );
};

const MainBody = () => {
  return (
    <div className="flex flex-col space-y-1 flex-grow">
      <StatisticPanel />
      <ChartPanel />
    </div>
  );
};

const Dashboard = () => {
  return (
    <div className="min-h-screen h-screen w-screen flex flex-col bg-gray-200">
      <HeaderMainPage />
      <MainBody />
    </div>
  );
};

export default Dashboard;
