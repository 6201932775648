import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SharedAuthUI from './SharedAuthUI';
import '../../../src/App.css';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const resetpw_api = process.env.REACT_APP_RESETPW_API

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await fetch(resetpw_api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: newPassword,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                alert(data.message + " Your password has been reset successfully.");
                navigate('/login');
            } else {
                const errorData = await response.json();
                setError(errorData.detail);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <SharedAuthUI>
            <div className="w-1/2 p-10 mt-8">
                <h2 className="font-bold text-2xl mb-6">Reset Password</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleResetPassword(); }} className="space-y-6">
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        className="w-full px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="w-full px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button type="submit" className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-300">Reset Password</button>
                </form>
                <button className="w-full mt-4 bg-white text-blue-600 py-3 border border-blue-600 rounded-md hover:bg-blue-50 transition duration-300" onClick={() => navigate('/login')}>Cancel</button>
                <p className="text-center text-sm text-gray-600 mt-6">
                    Remembered your password? <Link to="/login" className="text-blue-600 hover:underline">Sign In</Link>
                </p>
            </div>
        </SharedAuthUI>
    );
}

export default ResetPassword;
