import React from 'react';
import Logo from '../../assets/Logo.png';
import MailImage from '../../assets/mail.png';

const SharedAuthUI = ({ children }) => {
    return (
        <div className="bg-white min-h-screen flex flex-col items-center">
            <header className="w-full h-40 bg-transparent flex items-end justify-center mb-12"> 
                <img src={Logo} alt="Logo" className="w-40 h-auto" />
            </header>
            
            <div className="bg-white rounded-lg shadow-md flex w-full h-[600px] max-w-4xl overflow-hidden">
                <div className="w-1/2 bg-[#223F90] flex items-center justify-center">
                    <img src={MailImage} alt="Mail" className="w-full h-full object-cover scale-50" />
                </div>
                {children}
            </div>
            <p className="mt-6 text-sm text-gray-600">Copyright 2024. All rights reserved</p>
        </div>
    );
};

export default SharedAuthUI;
