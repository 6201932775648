import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import HeaderMainPage from '../../header/HeaderMainPage';

const MailReplyPage = () => {
  const navigate = useNavigate();
  const [showPromptBox, setShowPromptBox] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [replyText, setReplyText] = useState('');
  
  // New state for reply mail data
  const [replyMailData, setReplyMailData] = useState({
    title: "Re: Important Business Proposal",
    avatar: "https://via.placeholder.com/50",
    name: "Jane Smith",
    email: "jane.smith@example.com",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc id aliquam tincidunt, nisl nunc tincidunt nunc, vitae aliquam nunc nunc vitae nunc. Sed euismod, nunc id aliquam tincidunt, nisl nunc tincidunt nunc, vitae aliquam nunc nunc vitae nunc. Sed euismod, nunc id aliquam tincidunt, nisl nunc tincidunt nunc, vitae aliquam nunc nunc vitae nunc."
  });

  const handleAddPrompt = () => {
    setShowPromptBox(true);
  };

  const handleClosePromptBox = () => {
    setShowPromptBox(false);
  };

  const handleCreatePrompt = () => {
    // Replace the old prompt with the new one
    console.log('New prompt saved:', promptText);
    setShowPromptBox(false);
  };

  const handleGenerate = () => {
    // Function to handle generate button click
    // This will be implemented later
  };

  const handleReply = () => {
    // Save the reply text
    console.log('Reply saved:', replyText);
    // Additional logic for handling the reply can be added here
  };
  //

  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      <HeaderMainPage />

      <div className="flex w-4/6 h-full justify-between items-start my-10 mt-10">
        {/* mail-reply-view */}
        <div className="w-3/5 h-[400px] max-h-[700px] bg-white rounded-lg shadow-md" >
          <div className="h-[70px] bg-gray-100 p-4 rounded-t-lg flex justify-between items-center">
            <h2 className="text-xl font-bold mt-5 pl-2">{replyMailData.title}</h2>
          </div>
          <div className="flex items-center mt-2 mb-4 p-4">
            <img src={replyMailData.avatar} alt="Avatar" className="rounded-full mr-2" />
            <div>
              <p className="font-semibold">{replyMailData.name}</p>
              <p className="text-sm text-gray-600">{replyMailData.email}</p>
            </div>
          </div>
          <div className="border-t p-7">
            <p>{replyMailData.content}</p>
          </div>
        </div>

        {/* mail-reply-prompt */}
        <div className="w-2/5 max-h-[700px] flex flex-col ml-4">
          <div className="bg-white rounded-lg shadow-md mt-0 mb-4 flex-grow">

            <div className="h-[70px] bg-gray-100 p-2 rounded-t-lg flex justify-between items-center">
              <button
                className="bg-gray-500 text-white px-3 py-1 rounded-full text-sm ml-2 font-semi-bold"
                onClick={handleAddPrompt}
              >
                Add Prompt
              </button>
            </div>

            <div className="p-4 relative">
              <div className={`transition-all duration-300 ease-in-out ${showPromptBox ? 'max-h-[300px] opacity-100 mb-4' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                <div className="h-[200px] bg-white p-4 rounded-lg shadow-md flex flex-col">
                  <div className="flex justify-end mb-2">
                    <button
                      onClick={handleClosePromptBox}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      &times;
                    </button>
                  </div>
                  <textarea
                    placeholder="Enter prompt"
                    className="w-full p-2 rounded resize-none flex-grow focus:outline-none"
                    value={promptText}
                    onChange={(e) => setPromptText(e.target.value)}
                  />
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={handleCreatePrompt}
                      className="bg-[#223F9E] text-white text-xs px-3 py-2 rounded-full font-normal"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
              <textarea
                className="w-full p-2 rounded border"
                rows="10"
                placeholder="Type your reply here..."
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="flex justify-center space-x-4">
            <button
              onClick={handleGenerate}
              className="bg-gray-400 text-black px-6 py-2 rounded-full font-normal"
            >
              Generate
            </button>
            <button
              onClick={handleReply}
              className="bg-[#223F9E] text-white px-6 py-2 rounded-full font-normal"
            >
              Reply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailReplyPage;