// src/App.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';

const Button = ({ onClick, className, text }) => (
  <button onClick={onClick} className={`bg-blue-800 ${className}`}>
    {text}
  </button>
);

const InformationContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [CoreProduct, setCoreProduct] = useState('');
  const [Benefit, setBenefit] = useState('');
  const [TargetAudience, setTargetAudience] = useState('');
  const [IdealClient, setIdealClient] = useState('');
  const [UniqueSellingPoint, setUniqueSellingPoint] = useState('');

  useEffect(() => {
    // Load values from localStorage or set to empty string if not found
    setCoreProduct(localStorage.getItem('core_product') || '');
    setBenefit(localStorage.getItem('benefit') || '');
    setTargetAudience(localStorage.getItem('target_audience') || '');
    setIdealClient(localStorage.getItem('ideal_client') || '');
    setUniqueSellingPoint(localStorage.getItem('unique_selling_point') || '');
  }, []);
  
  const handleExit = () => {
    navigate('/campinitselect');
  };

  const handleNext = () => {
    if (!CoreProduct || !Benefit || !TargetAudience || !IdealClient || !UniqueSellingPoint) {
      Swal.fire({
        title: 'Missing Information',
        text: 'Please fill in all fields before proceeding.',
        icon: 'warning',
        confirmButtonText: 'Close',
        confirmButtonColor: "#223F9E"
      });
    } else {
      // Save all fields to localStorage
      localStorage.setItem('core_product', CoreProduct);
      localStorage.setItem('benefit', Benefit);
      localStorage.setItem('target_audience', TargetAudience);
      localStorage.setItem('ideal_client', IdealClient);
      localStorage.setItem('unique_selling_point', UniqueSellingPoint);
      
      navigate('/onboarding-plan');
    }
  };
  
  return (
    <div>
      <div className="w-full bg-gray-100 h-[50px] border rounded-md flex items-center justify-center">
        <div className="w-full flex justify-between items-center pl-8 pr-8">
          <h2 className="text-xl font-bold">Your situation</h2>
          <button onClick={handleExit} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
        </div>
      </div>

      <form className="mt-4 mr-8 ml-8 mb-4 space-y-4">
        <div>
          <label htmlFor="core product" className="block text-sm font-medium text-gray-700 mb-1">Core Product:</label>
          <input
            type="text"
            id="core product"
            value={CoreProduct}
            onChange={(e) => setCoreProduct(e.target.value)}
            required
            className="bg-gray-100 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder='Input the name of core product'
          />
        </div>

        <div>
          <label htmlFor="benefit" className="block text-sm font-medium text-gray-700 mb-1">Benefit:</label>
          <input
            type="text"
            id="benefit"
            value={Benefit}
            onChange={(e) => setBenefit(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input benefit of core product'
          />
        </div>

        <div>
          <label htmlFor="target audience" className="block text-sm font-medium text-gray-700 mb-1">Target Audience:</label>
          <input
            type="text"
            id="target audience"
            value={TargetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the target audience'
          />
        </div>

        <div>
          <label htmlFor="ideal client" className="block text-sm font-medium text-gray-700 mb-1">Ideal Client:</label>
          <input
            type="text"
            id="ideal client"
            value={IdealClient}
            onChange={(e) => setIdealClient(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the ideal client'
          />
        </div>

        <div>
          <label htmlFor="unique selling point" className="block text-sm font-medium text-gray-700 mb-1">Unique Selling Point:</label>
          <input
            type="text"
            id="unique selling point"
            value={UniqueSellingPoint}
            onChange={(e) => setUniqueSellingPoint(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
            placeholder='Input the unique selling point'
          />
        </div>

      </form>

      <div className="flex justify-center mt-[30px] mb-[30px]">
        <button 
          className="bg-[#223F9E] text-white py-2 px-8 rounded-full hover:bg-blue-700 transition-colors duration-200"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const CampManualFill = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
        <HeaderMainPage />
        <div className="w-4/6 h-full items-start rounded-lg shadow-md my-5 mt-[30px] rounded-md bg-white">
          <InformationContainer />
        </div>
      </div>
  );
};

export default CampManualFill;
