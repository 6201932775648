import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import whiteLogo from '../assets/Logo_white.png';
import profileImage from '../assets/profile.png';

const HeaderMainPage = () => {
  const navigate = useNavigate();
  const [showCampaignMenu, setShowCampaignMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const campaignMenuRef = useRef(null);
  const profileMenuRef = useRef(null);

  const commonButtonStyle = "bg-transparent py-2 px-4 text-white cursor-pointer text-lg font-semibold hover:bg-blue-700 transition-colors duration-200 rounded-full";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (campaignMenuRef.current && !campaignMenuRef.current.contains(event.target)) {
        setShowCampaignMenu(false);
      }
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleViewInformation = () => {
    navigate('/situation');
  };

  const Dashboard = () => {
    navigate('/dashboard');
    // Implementation to be added later
  };

  const findData = () => {
    console.log("Find Data function called");
    // Implementation to be added later
  };

  const newCampaign = () => {
    navigate('/onboarding-select');
    const keysToRemove = [
      'campaignDescription',
      'campaignMustHaveInfo',
      'campaignOutputFormat',
      'campaign_name',
      'companyType',
      'core_service',
      'ideal_client',
      'key_benefits',
      'linkedInResult',
      'linkedinUrl',
      'messagePairs',
      'nickname',
      'primary_goal_of_outreach_campaign',
      'problem_solved',
      'selectedPlan',
      'success_measurement',
      'targetIndustry',
      'target_audience',
      'unique_selling_proposition',
      'campaignData',
    ]
    // const keysToRemove = ['campaign_name', 'industry','target_audience','unique_selling_point','core_product','ideal_client','goal','campaignName','campaignDescription','campaignMustHaveInfo','campaignOutputFormat','campaignState'];

    keysToRemove.forEach(key => {
        localStorage.removeItem(key);
    });
  };

  const myCampaign = () => {
    navigate('/my-campaign');
  };

  const dataLibrary = () => {
    console.log("Data Library function called");
    // Implementation to be added later
  };

  const toggleCampaignMenu = () => {
    setShowCampaignMenu(!showCampaignMenu);
    setShowProfileMenu(false);
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
    setShowCampaignMenu(false);
  };

  return (
    <header className="w-full h-20 flex justify-between items-center px-12 bg-[#223F9E] text-white">
      <div className="flex-none w-[150px] flex justify-start items-center">
        <img src={whiteLogo} alt="White Logo" className="w-[130px] h-[50px] object-contain" />
      </div>

      <nav className="flex-1 flex justify-center items-center space-x-5">
        <button onClick={Dashboard} className={commonButtonStyle}>Dashboard</button>
        <button onClick={findData} className={commonButtonStyle}>Find Data</button>
        <div className="relative" ref={campaignMenuRef}>
          <button onClick={toggleCampaignMenu} className={commonButtonStyle}>
            Campaign <span className="ml-1">▼</span>
          </button>
          {showCampaignMenu && (
            <div className="absolute top-full left-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 mt-1">
              <button onClick={newCampaign} className="block w-[150px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-t-md font-semibold">New Campaign</button>
              <button onClick={myCampaign} className="block w-[150px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">My Campaign</button>
            </div>
          )}
        </div>
        <button onClick={dataLibrary} className={commonButtonStyle}>Data Library</button>
      </nav>

      <div className="flex-none w-[150px] flex justify-end items-center relative" ref={profileMenuRef}>
        <div className="flex items-center cursor-pointer" onClick={toggleProfileMenu}>
          <img src={profileImage} alt="Profile" className="w-10 h-10 rounded-full object-cover mr-1" />
          <span className="text-xs ml-1">▼</span>
        </div>
        {showProfileMenu && (
          <div className="absolute top-full right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 mt-1">
            {/* <button className="block w-[180px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-t-md font-semibold" onClick={handleViewInformation}>View Information</button> */}
            <button className="block w-[180px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold" onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>
    </header>
  );
};

export default HeaderMainPage;
