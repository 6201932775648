import React, { useState, useEffect, useRef, useCallback } from 'react';
import HeaderMainPage from '../../header/HeaderMainPage';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useVoiceInput } from './VoiceInput';
import profileImage from '../../assets/profile.png';
import AIimage from '../../assets/image1.png';

const UserAIChatUI = () => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [messagePairs, setMessagePairs] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [showButtons, setShowButtons] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isBotResponding, setIsBotResponding] = useState(false);
    const [isChatDisabled, setIsChatDisabled] = useState(false);
    const [campaignName, setCampaignName] = useState('');
    const [coreService, setCoreService] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [uniqueSellingProposition, setUniqueSellingProposition] = useState('');
    const [problemSolved, setProblemSolved] = useState('');
    const [keyBenefits, setKeyBenefits] = useState('');
    const [primaryGoalOfOutreachCampaign, setPrimaryGoalOfOutreachCampaign] = useState('');
    const [idealClient, setIdealClient] = useState('');
    const [successMeasurement, setSuccessMeasurement] = useState('');
    const [fullResponse, setFullResponse] = useState('');
    const messagesEndRef = useRef(null);

    const { startListening, stopListening, getTranscript, isListening, browserSupportsSpeechRecognition, resetTranscript } = useVoiceInput();
    
    const converid_api = process.env.REACT_APP_GETCONVERID_API
    const parser_api = process.env.REACT_APP_PARSER_API
    const send_message_api = process.env.REACT_APP_SEND_MESSAGE_API

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const storedCampaignName = localStorage.getItem('campaign_name') || '';
        const storedCoreService = localStorage.getItem('core_service') || '';
        const storedTargetAudience = localStorage.getItem('target_audience') || '';
        const storedUniqueSellingProposition = localStorage.getItem('unique_selling_proposition') || '';
        const storedProblemSolved = localStorage.getItem('problem_solved') || '';
        const storedKeyBenefits = localStorage.getItem('key_benefits') || '';
        const storedPrimaryGoalOfOutreachCampaign = localStorage.getItem('primary_goal_of_outreach_campaign') || '';
        const storedIdealClient = localStorage.getItem('ideal_client') || '';
        const storedSuccessMeasurement = localStorage.getItem('success_measurement') || '';
        const storedMessagePairs = JSON.parse(localStorage.getItem('messagePairs')) || [];

        setCampaignName(storedCampaignName);
        setCoreService(storedCoreService);
        setTargetAudience(storedTargetAudience);
        setUniqueSellingProposition(storedUniqueSellingProposition);
        setProblemSolved(storedProblemSolved);
        setKeyBenefits(storedKeyBenefits);
        setPrimaryGoalOfOutreachCampaign(storedPrimaryGoalOfOutreachCampaign);
        setIdealClient(storedIdealClient);
        setSuccessMeasurement(storedSuccessMeasurement);
        setMessagePairs(storedMessagePairs);

        if (storedCampaignName && storedCoreService && storedTargetAudience &&
            storedUniqueSellingProposition && storedProblemSolved && storedKeyBenefits &&
            storedPrimaryGoalOfOutreachCampaign && storedIdealClient && storedSuccessMeasurement) {
            // const summaryMessage = `Here are your summary information: 
            // **Campaign Name**: ${storedCampaignName}
            // **Core Service**: ${storedCoreService}
            // **Target Audience**: ${storedTargetAudience}
            // **Unique Selling Proposition**: ${storedUniqueSellingProposition}
            // **Problem Solved**: ${storedProblemSolved}
            // **Key Benefits**: ${storedKeyBenefits}
            // **Primary Goal of Outreach Campaign**: ${storedPrimaryGoalOfOutreachCampaign}
            // **Ideal Client**: ${storedIdealClient}
            // **Success Measurement**: ${storedSuccessMeasurement}`;
            // setMessages([{ sender: 'bot', text: summaryMessage }]);
            // setShowButtons(true);
            // setIsChatDisabled(true);
        } else {
            setMessages([{ sender: 'bot', text: "I'm here to help you set up your campaign. Let's chat with AI to receive the step-by-step instruction" }]);
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // This useCallback ensures that the function is only recreated when its dependencies change
    const checkAllFieldsFilled = useCallback(() => {
        return campaignName && coreService && targetAudience && uniqueSellingProposition && problemSolved && keyBenefits && primaryGoalOfOutreachCampaign && idealClient && successMeasurement;
    }, [campaignName, coreService, targetAudience, uniqueSellingProposition, problemSolved, keyBenefits, primaryGoalOfOutreachCampaign, idealClient, successMeasurement]);

    // This useEffect runs whenever the campaign info changes, updating the UI accordingly
    useEffect(() => {
        if (checkAllFieldsFilled()) {
            setShowButtons(true);
            setIsChatDisabled(true);
            const welcomeMessage = `Here are your summary information: 
            **Campaign Name**: ${campaignName}
            **Core Service/Product**: ${coreService}
            **Target Audience**: ${targetAudience}
            **Unique Selling Proposition**: ${uniqueSellingProposition}
            **Problem Solved**: ${problemSolved}
            **Key Benefits**: ${keyBenefits}
            **Primary Goal of Outreach Campaign**: ${primaryGoalOfOutreachCampaign}
            **Ideal Client**: ${idealClient}
            **Success Measurement**: ${successMeasurement}`;
            setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: welcomeMessage }]);
        } else {
            setShowButtons(false);
            setIsChatDisabled(false);
        }
    }, [checkAllFieldsFilled]);

    // This useEffect handles the extraction of campaign info once the full response is received
    useEffect(() => {
        if (!isStreaming && fullResponse) {
            const lastMessage = messages[messages.length - 1];
            // extractCampaignInfo(lastMessage.text)
            if (lastMessage && lastMessage.sender === 'bot' && (lastMessage.text.toLowerCase().includes('review')||lastMessage.text.toLowerCase().includes('finalize')||lastMessage.text.toLowerCase().includes('finalize'))) { //lastMessage.text.toLowerCase().includes('review')
                // The .then() ensures that fullResponse is only cleared after extractCampaignInfo completes
                extractCampaignInfo(fullResponse).then(() => {
                    setFullResponse('');
                });
            } else {
                setFullResponse('');
            }
        }
    }, [isStreaming, fullResponse, messages]);

    useEffect(() => {
        const transcript = getTranscript();
        if (transcript) {
            setInputMessage(transcript);
        }
    }, [getTranscript]);

    const getConversationId = async () => {
        try {
            const response = await fetch(converid_api, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    // Add any necessary data for this API call
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.conversation_id) {
                localStorage.setItem('conversation_id', data.conversation_id);
            }
        } catch (error) {
            console.error('Error getting conversation ID:', error);
        }
    };

    const extractCampaignInfo = async (text) => {
        try {
            const response = await fetch(parser_api, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    input_str: JSON.stringify(text)
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data) {
                console.log(data);
                localStorage.setItem('campaign_name', data.campaign_name);
                localStorage.setItem('core_service', data.core_service);
                localStorage.setItem('unique_selling_proposition', data.unique_selling_proposition);
                localStorage.setItem('target_audience', data.target_audience);
                localStorage.setItem('problem_solved', data.problem_solved);
                localStorage.setItem('key_benefits', data.key_benefits);
                localStorage.setItem('primary_goal_of_outreach_campaign', data.primary_goal_of_outreach_campaign);
                localStorage.setItem('ideal_client', data.ideal_client);
                localStorage.setItem('success_measurement', data.success_measurement);
                localStorage.setItem('suggest_usp', data);
                localStorage.setItem('location', data);
                localStorage.setItem('industry', data);
                
                // Return a Promise to ensure all state updates are complete before moving on
                return new Promise((resolve) => {
                    setCampaignName(data.campaign_name);
                    setCoreService(data.core_service);
                    setTargetAudience(data.target_audience);
                    setUniqueSellingProposition(data.unique_selling_proposition);
                    setProblemSolved(data.problem_solved);
                    setKeyBenefits(data.key_benefits);
                    setPrimaryGoalOfOutreachCampaign(data.primary_goal_of_outreach_campaign);
                    setIdealClient(data.ideal_client);
                    setSuccessMeasurement(data.success_measurement);
                    resolve();
                });
            }
        } catch (error) {
            console.error('Error extracting campaign info:', error);
        }
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === '' || isBotResponding) return;

        if (isListening) {
            stopListening();
        }

        setIsBotResponding(true);
        setMessages(prev => [...prev, { sender: 'user', text: inputMessage }]);
        resetTranscript();
        setInputMessage(''); // Clear input message after sending
        setShowButtons(false);
        setIsStreaming(true);
        setFullResponse('');

        // If this is the first message, get the conversation ID
        if (messages.length === 1 &&  localStorage.getItem("messagePairs") == null) {
            await getConversationId();
        }

        let accumulatedResponse = '';

        try {
            await axios({
                method: 'post',
                url: send_message_api,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                data: {
                    conversation_id: localStorage.getItem('conversation_id'),
                    message_content: inputMessage,
                    model_id: 1,
                },
                responseType: 'stream',
                onDownloadProgress: (progressEvent) => {
                    const { response } = progressEvent.event.currentTarget;
                    console.log(response)
                    
                    accumulatedResponse += response;
                    
                    setMessages(prev => {
                        const updatedMessages = [...prev];
                        if (updatedMessages[updatedMessages.length - 1].sender === 'bot') {
                            updatedMessages[updatedMessages.length - 1].text = response;
                        } else {
                            updatedMessages.push({ sender: 'bot', text: response });
                        }
                        return updatedMessages;
                    });
                }
            });

            setIsStreaming(false);
            setFullResponse(accumulatedResponse);
            
            setMessagePairs(prev => {
                const updatedPairs = Array.isArray(prev) ? [...prev, [inputMessage, accumulatedResponse]] : [[inputMessage, accumulatedResponse]];
                localStorage.setItem('messagePairs', JSON.stringify(updatedPairs));
                return updatedPairs;
            });

            if (checkAllFieldsFilled()) {
                setShowButtons(true);
                setIsChatDisabled(true);
            }
        } catch (error) {
            console.error('Error:', error);
            let errorMessage = "Sorry, I encountered an error. Please try again.";
            if (error.response) {
                errorMessage = `Server error. Please try again later.`;
            } else if (error.request) {
                errorMessage = "No response from the server. Please check your internet connection.";
            } else {
                errorMessage = "An unexpected error occurred. Please try again.";
            }
            setMessages(prev => [...prev, { sender: 'bot', text: errorMessage }]);
        } finally {
            setIsBotResponding(false);
            setIsStreaming(false);
        }
    };

    const handleConfirm = () => {
        console.log('Confirmed and proceeding');
        setShowButtons(false);
        navigate('/onboarding-plan')
    };

    const handleAddDetails = () => {
        console.log('Adding more details');
        setShowButtons(false);
        setIsChatDisabled(false);
    };

    const handleVoiceInput = () => {
        if (isListening) {
            stopListening();
        } else {
            startListening();
        }
    };

    const messageBubbleStyle = {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        width: 'fit-content',
    };

    return (
        <div className="bg-white min-h-screen flex flex-col items-center">
            <HeaderMainPage />
            <div className="w-full h-20 flex justify-center items-center bg-white border-b-4 border-gray-300">
                <h1 className="text-blue-800 text-sm font-bold">HELP US CUSTOMIZE YOUR EXPERIENCE WITH OUR ASSISTANT!</h1>
            </div>
            <div className='h-full w-full flex justify-center'>
                <div className="flex flex-col item-start justify-center w-[70%] h-[700px] bg-gray-100">
                    <div className="flex-1 overflow-y-auto p-4 bg-white scroll-smooth">
                        {messages.map((message, index) => (
                            <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                                <div className={`flex ${message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'} items-end mb-4`}>
                                    <div className={`w-10 h-10 rounded-full flex-shrink-0 overflow-hidden ${message.sender === 'user' ? 'bg-white-100' : 'bg-blue-500'}`}>
                                        <img 
                                            src={message.sender === 'user' ? profileImage : AIimage} 
                                            alt={message.sender === 'user' ? 'User Avatar' : 'AI Avatar'}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <div 
                                        className={`mx-4 py-2 px-4 max-w-[900px] rounded-lg ${message.sender === 'user' ? 'bg-[#edf1ff] text-black' : 'bg-gray-200'}`}
                                        style={messageBubbleStyle}
                                    >
                                        {message.sender === 'user' ? (
                                            message.text
                                        ) : (
                                            <ReactMarkdown>{message.text}</ReactMarkdown>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>

                    {showButtons && (
                        <div className="flex justify-center space-x-4 p-4 bg-white">
                            <button onClick={handleConfirm} className="bg-[#223F9E] text-white px-4 py-2 rounded-full font-semibold text-sm">
                                Confirm and Proceed
                            </button>
                            <button onClick={handleAddDetails} className="bg-gray-500 text-white px-4 py-2 rounded-full font-semibold text-sm">
                                Add More Details
                            </button>
                        </div>
                    )}

                    <div className="p-4 bg-white">
                        <div className="flex items-center bg-gray-100 rounded-xl p-2 ml-10 mr-10 max-h-[300px]">
                            <textarea
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                className="flex-1 bg-transparent outline-none pl-5 resize-none h-50 overflow-y-auto flex justify-center"
                                placeholder="Type your message..."
                                disabled={isBotResponding || isChatDisabled}
                                rows={3}
                            />
                            {browserSupportsSpeechRecognition && (
                                <button 
                                    onClick={handleVoiceInput}
                                    className={`mr-2 ${isBotResponding || isChatDisabled ? 'bg-gray-400' : isListening ? 'bg-red-500' : 'bg-[#223F9E]'} text-white rounded-full p-2`}
                                    disabled={isBotResponding || isChatDisabled}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                                    </svg>
                                </button>
                            )}
                            <button 
                                onClick={handleSendMessage}
                                className={`mr-2 ${isBotResponding || isChatDisabled ? 'bg-gray-400' : 'bg-[#223F9E]'} text-white rounded-full p-2`}
                                disabled={isBotResponding || isChatDisabled}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserAIChatUI;
