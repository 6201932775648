import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export const useVoiceInput = () => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({
    interimResults: true
  });

  const startListening = (isContinuous = true) => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: isContinuous });
    } else {
      console.error("Browser doesn't support speech recognition.");
    }
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  const getTranscript = () => {
    return transcript;
  };

  return {
    startListening,
    stopListening,
    getTranscript,
    isListening: listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  };
};

export default useVoiceInput;
