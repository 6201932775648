import React, { useState, useEffect } from 'react';
import HeaderMainPage from '../../header/HeaderMainPage';
import { useNavigate } from 'react-router-dom';

const StarIcon = ({ filled }) => (
  <svg className={`w-6 h-6 ${filled ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

const ThreeDotsIcon = () => (
  <svg className="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
  </svg>
);

// Define column widths
const columnWidths = {
  checkbox: '50px',
  star: '50px',
  campaign: '300px',
  deliveryDate: '150px',
  loremIpsum1: '150px',
  loremIpsum2: '150px',
  actions: '100px',
};

// Static campaign data
// const staticCampaigns = [
//   { id: 1, name: "Summer Sale", library: "Email", deliveryDate: "7/15/2023", isStarred: true, isSelected: false },
//   { id: 2, name: "Back to School", library: "Social Media", deliveryDate: "8/20/2023", isStarred: false, isSelected: false },
//   { id: 3, name: "Holiday Special", library: "Display Ads", deliveryDate: "12/1/2023", isStarred: true, isSelected: false },
//   { id: 4, name: "New Product Launch", library: "Content Marketing", deliveryDate: "9/10/2023", isStarred: false, isSelected: false },
//   { id: 5, name: "Customer Appreciation", library: "Email", deliveryDate: "10/5/2023", isStarred: true, isSelected: false },
//   { id: 6, name: "Flash Sale", library: "SMS", deliveryDate: "11/15/2023", isStarred: false, isSelected: false },
//   { id: 7, name: "End of Season", library: "Email", deliveryDate: "8/31/2023", isStarred: true, isSelected: false },
//   { id: 8, name: "Black Friday", library: "Display Ads", deliveryDate: "11/24/2023", isStarred: false, isSelected: false },
//   { id: 9, name: "Cyber Monday", library: "Social Media", deliveryDate: "11/27/2023", isStarred: true, isSelected: false },
//   { id: 10, name: "Spring Collection", library: "Influencer Outreach", deliveryDate: "3/1/2024", isStarred: false, isSelected: false },
//   { id: 11, name: "Loyalty Rewards", library: "Email", deliveryDate: "9/1/2023", isStarred: true, isSelected: false },
//   { id: 12, name: "Referral Program", library: "Content Marketing", deliveryDate: "10/15/2023", isStarred: false, isSelected: false },
//   { id: 13, name: "Winter Clearance", library: "Display Ads", deliveryDate: "1/5/2024", isStarred: true, isSelected: false },
//   { id: 14, name: "Valentine's Day", library: "Social Media", deliveryDate: "2/14/2024", isStarred: false, isSelected: false },
//   { id: 15, name: "Easter Promotion", library: "Email", deliveryDate: "4/1/2024", isStarred: true, isSelected: false },
//   { id: 16, name: "Mother's Day", library: "Influencer Outreach", deliveryDate: "5/12/2024", isStarred: false, isSelected: false },
//   { id: 17, name: "Father's Day", library: "Content Marketing", deliveryDate: "6/16/2024", isStarred: true, isSelected: false },
//   { id: 18, name: "Independence Day", library: "SMS", deliveryDate: "7/4/2024", isStarred: false, isSelected: false },
//   { id: 19, name: "Labor Day", library: "Display Ads", deliveryDate: "9/2/2024", isStarred: true, isSelected: false },
//   { id: 20, name: "Halloween", library: "Social Media", deliveryDate: "10/31/2024", isStarred: false, isSelected: false },
// ];

const MyCampaign = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [activeTab, setActiveTab] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const get_campaign_api = process.env.REACT_APP_GET_CAMPAIGN

  // Function to update column width if needed in the future
  const updateColumnWidth = (column, width) => {
    columnWidths[column] = width;
    // Force re-render
    setCampaigns([...campaigns]);
  };


// New function to fetch user data from API
const fetchCampaignDataFromAPI = async (access_token) => {
  try {
    const response = await fetch(get_campaign_api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': access_token,
        },
    });
    

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }
    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Error fetching user campaign data:', error);
    return null;
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }); // Output: "11/07/2024"
};

useEffect(() => {
  const fetchData = async () => {
    // First, try to load data from localStorage
    const access_token = localStorage.getItem('access_token')
    const userCampaign = await fetchCampaignDataFromAPI(access_token);
    
    if (userCampaign) {
      // If API fetch is successful, use the data
      localStorage.setItem('campaign_data', userCampaign);

      const staticCampaigns = userCampaign.map((item, index) => ({
        id: index + 1,
        name: item.campaign_name,
        deliveryDate: formatDate(item.created_at),
        isStarred: false,
        isSelected: false
      }));
      setCampaigns(staticCampaigns);

    }
  };

  fetchData();

  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleStarClick = (id) => {
    setCampaigns(campaigns.map(campaign => 
      campaign.id === id ? { ...campaign, isStarred: !campaign.isStarred } : campaign
    ));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCampaigns(campaigns.map(campaign => ({ ...campaign, isSelected: !selectAll })));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDropdownToggle = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const handleDelete = (id) => {
    setCampaigns(campaigns.filter(campaign => campaign.id !== id));
    setOpenDropdown(null);
  };

  const handleButtonCreateCampaign = () => {
    
    const keysToRemove = [
      'campaignDescription',
      'campaignMustHaveInfo',
      'campaignOutputFormat',
      'campaign_name',
      'companyType',
      'core_service',
      'ideal_client',
      'key_benefits',
      'linkedInResult',
      'linkedinUrl',
      'messagePairs',
      'nickname',
      'primary_goal_of_outreach_campaign',
      'problem_solved',
      'selectedPlan',
      'success_measurement',
      'targetIndustry',
      'target_audience',
      'unique_selling_proposition',
      'campaignData',
    ]

    keysToRemove.forEach(key => {
        localStorage.removeItem(key);
    
    navigate('/onboarding-select');
    });
  };

  // New function to handle Details button click
  const handleDetails = (campaign) => {
    // Save the selected campaign name in local storage
    localStorage.setItem('selectedCampaign', campaign.name);
    // Navigate to the details page
    navigate(`/email-status`);
  };

  const filteredCampaigns = campaigns.filter(campaign => {
    if (activeTab === 'All') return true;
    if (activeTab === 'Starred') return campaign.isStarred;
    return true; // For other tabs, show all for now
  }).filter(campaign => 
    campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    campaign.library.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-gray-100 min-h-screen">
      <HeaderMainPage />
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 mt-[80px] shadow-md bg-white rounded-md">
        <div className="flex justify-between items-center mb-6 p-3">
          <h1 className="text-3xl font-bold text-gray-900">My Campaign</h1>
          <button className="bg-blue-600 text-white px-4 py-2 font-semibold rounded-md hover:bg-blue-700 transition duration-300" onClick={handleButtonCreateCampaign}>
            + Create New Campaign
          </button>
        </div>

        <div className="border-b border-gray-200 mb-6">
          {['All', 'Starred', 'Draft', 'Sent', 'Scheduled', 'Archived'].map(tab => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`px-4 py-2 mr-4 focus:outline-none ${
                activeTab === tab
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="flex items-center mb-6">
          <div className="flex items-center ml-6 mr-4">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              className="form-checkbox h-5 w-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500"
            />
          </div>
          <div className="relative flex-grow max-w-xs ml-10">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div className="max-h-[calc(90vh-400px)] overflow-y-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th style={{ width: columnWidths.checkbox }}></th>
                  <th style={{ width: columnWidths.star }}></th>
                  <th style={{ width: columnWidths.campaign }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
                  <th style={{ width: columnWidths.deliveryDate }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Delivery Date</th>
                  <th style={{ width: columnWidths.loremIpsum1 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lorem Ipsum</th>
                  <th style={{ width: columnWidths.loremIpsum2 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lorem Ipsum</th>
                  <th style={{ width: columnWidths.actions }} className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredCampaigns.map(campaign => (
                  <tr key={campaign.id}>
                    <td className="px-6 py-4 whitespace-nowrap" style={{ width: columnWidths.checkbox }}>
                      <input
                        type="checkbox"
                        checked={campaign.isSelected}
                        onChange={() => {
                          setCampaigns(campaigns.map(c => 
                            c.id === campaign.id ? { ...c, isSelected: !c.isSelected } : c
                          ));
                        }}
                        className="form-checkbox h-5 w-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap" style={{ width: columnWidths.star }}>
                      <button onClick={() => handleStarClick(campaign.id)} className="focus:outline-none">
                        <StarIcon filled={campaign.isStarred} />
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap" style={{ width: columnWidths.campaign }}>
                      <div>
                        <div className="text-sm font-medium text-gray-900">{campaign.name}</div>
                        <div className="text-sm text-gray-500">{campaign.library}</div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.deliveryDate }}>{campaign.deliveryDate}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.loremIpsum1 }}></td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.loremIpsum2 }}>Regular text column</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" style={{ width: columnWidths.actions }}>
                      <div className="relative">
                        <button 
                          onClick={() => handleDropdownToggle(campaign.id)} 
                          className="text-gray-400 hover:text-gray-500 focus:outline-none"
                        >
                          <ThreeDotsIcon />
                        </button>
                        {openDropdown === campaign.id && (
                          <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              <button
                                onClick={() => handleDetails(campaign)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                role="menuitem"
                              >
                                Details
                              </button>
                              <button
                                onClick={() => handleDelete(campaign.id)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                role="menuitem"
                              >
                                Delete
                              </button>
                              {/* Add more dropdown options here */}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCampaign;
