import React, { useEffect, useState } from 'react';
import {OnboardingHeaderPart} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import discInfo from '../../assets/disc_info.json';
import oceanInfo from '../../assets/ocean_info.json';
import Swal from 'sweetalert2';

const OnboardingReviewUser = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const apikey = process.env.REACT_APP_HUMANTICAI_KEY;
    const linkedinUrl = localStorage.getItem("linkedinUrl")

    const handlingFetchLinkedIn = async (apikey, id) => {
        try {
          const response = await fetch(`https://api.humantic.ai/v1/user-profile?apikey=${apikey}&id=${id}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching LinkedIn data:', error);
          return { data: { status: 'error' } };
        }
      };

    const createUserData = (data) => ({
        user_profile_image: data.user_profile_image || '',
        display_name: data.display_name || '',
        work_history: data.work_history?.length > 0 ? data.work_history[0].title : 'unemployed',
        location: data.location || '',
        skills: data.skills || [],
        disc_description: data.personality_analysis?.summary?.disc?.description || '',
        ocean_description: data.personality_analysis?.summary?.ocean?.description || [],
        disc_label: data.personality_analysis?.summary?.disc?.label || [],
        ocean_label: data.personality_analysis?.summary?.ocean?.label || []
    });

    useEffect(() => {


        const loadUserData = async () => {
            const storedData = localStorage.getItem('linkedInResult');
    
            if (storedData && storedData !== "undefined") {
                console.log("founding data");
                try {
                    const parsedData = JSON.parse(storedData);
                    setUserData(createUserData(parsedData));
                    console.log(parsedData);
                } catch (error) {
                    console.error('Error parsing LinkedIn data:', error);
                }
            } else {
                console.log("not founding data");
                try {
                    const fetchedData = await handlingFetchLinkedIn(apikey, linkedinUrl);
                    if (fetchedData && fetchedData.results) {
                        console.log(fetchedData.results);
                        localStorage.setItem('linkedInResult', JSON.stringify(fetchedData.results));
                        setUserData(createUserData(fetchedData.results));
                    } else {
                        console.log("not founding data");

                        // Show SweetAlert to inform the user about the delay
                        Swal.fire({
                            icon: 'info',
                            title: 'Processing',
                            text: 'The processing will take time. Please wait some more minutes and refresh the page.',
                            confirmButtonColor: "#223F9E",
                            confirmButtonText: 'Close'
                        });

                        // Set a timer to wait for 3 minutes before attempting to fetch data
                        setTimeout(async () => {
                            try {
                                const fetchedData = await handlingFetchLinkedIn(apikey, linkedinUrl);
                                if (fetchedData && fetchedData.results) {
                                    console.log(fetchedData.results);
                                    localStorage.setItem('linkedInResult', JSON.stringify(fetchedData.results));
                                    setUserData(createUserData(fetchedData.results));
                                } else {
                                    // If still no data, show SweetAlert
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Processing Delay',
                                        text: 'The processing will take time. Please try again later.',
                                        confirmButtonColor: "#223F9E",
                                        confirmButtonText: 'Close'
                                    });
                                }
                            } catch (error) {
                                console.error('Error fetching LinkedIn data:', error);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'An error occurred while fetching data. Please try again later.',
                                    confirmButtonColor: "#223F9E",
                                    confirmButtonText: 'Close'
                                });
                            }
                        }, 180000); // 3 minutes in milliseconds
                    }
                } catch (error) {
                    console.error('Error fetching LinkedIn data:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'An error occurred while fetching data. Please try again later.',
                        confirmButtonColor: "#223F9E",
                        confirmButtonText: 'Close'
                    });
                }
            }
        };

        loadUserData();
    }, [apikey, linkedinUrl]);
    
    const WelcomeString = () => {
        return (
        <div>
            <h2 className="text-2xl font-bold text-center">
            Your Profile, All Wrapped Up
            </h2>
        </div>
        );
    };

    const UserBasicInfo = () => {
        if (!userData) return null;

        return (
        <div className="w-full max-w-[1300px] h-auto flex flex-col md:flex-row p-6 bg-white items-center space-x-4 space-y-4 md:space-y-0 rounded-2xl shadow-md">
            {/* Avatar Logo Placeholder */}
            <div className="w-20 h-20 text-center bg-gray-300 flex items-center justify-center rounded-full ml-5 mr-5">
                {userData.user_profile_image ? (
                    <img src={userData.user_profile_image} alt="User Profile" className="w-full h-full object-cover rounded-full" />
                ) : (
                    "Avatar Logo"
                )}
            </div>

            <div className="flex flex-col space-y-2">
            <div className="text-lg md:text-xl font-bold">{userData.display_name}</div>
            <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                {/* Gender */}
                <div className="flex items-center space-x-1">
                    {/* Note: Import Gender Logo Here */}
                    <div className="w-5 h-5 bg-gray-300 rounded-xl"></div>
                    <div className="px-2">{userData.work_history}</div>
                </div>
                {/* Location */}
                <div className="md:p-3 flex items-center space-x-1">
                    {/* Note: Import Location Logo Here */}
                    <div className="w-5 h-5 bg-gray-300 rounded-xl"></div>
                    <div className="px-2">{userData.location}</div>
                </div>
            </div>
            <div className="flex flex-wrap space-x-2">
                {userData.skills.map((skill, index) => (
                <div key={index} className="bg-blue-500 text-white px-2 py-1 rounded mb-2">
                    {skill}
                </div>
                ))}
            </div>
            </div>
        </div>
        );
    };

    const ContentBox = ({ title, content }) => {
        return (
          <div className="p-4 w-[250px] h-[280px] min-w-[250px] min-h-[280px] bg-gray-100 rounded-lg">
            <div className="text-blue-800 text-sm font-bold mb-2">{title}</div>
            <div className="text-sm mb-2">{content}</div>
          </div>
        );
      };

    const DiscProfile = () => {
        if (!userData) return null;

        const getDiscDescription = (label) => {
            const trait = discInfo.disc.find(t => t.abbreviation.toLowerCase() === label.toLowerCase());
            return trait ? trait.description : "Description not available";
        };

        return (
        <div className="p-3 w-full bg-white flex flex-col space-y-4 rounded-xl shadow-md items-center ">
            {/* Title */}
            <div className="text-center text-lg font-bold">Disc Profile</div>
            {/* Disc Label */}
            <div className="flex flex-wrap justify-center items-center space-x-2">
                {userData.disc_label.map((label, index) => (
                    <div key={index} className="bg-yellow-400 text-white py-1 px-4 rounded mb-2">
                        {label}
                    </div>
                ))}
            </div>
            {userData.disc_label.map((label, index) => (
                <ContentBox key={index} title={`${label} - ${discInfo.disc.find(t => t.abbreviation.toLowerCase() === label.toLowerCase())?.trait}`} content={getDiscDescription(label)} />
            ))}
        </div>
        );
    };

    const OceanProfile = () => {
        if (!userData) return null;

        const getOceanDescription = (label) => {
            const trait = oceanInfo.ocean.find(t => t.abbreviation.toLowerCase() === label.toLowerCase());
            return trait ? trait.description : "Description not available";
        };

        return (
        <div className="p-3 bg-white flex flex-col space-y-4 rounded-xl h-full items-center shadow-md ">
            {/* Title */}
            <div className="text-center text-lg font-bold">Ocean Profile</div>
            {/* OCEAN Label */}
            <div className="flex flex-wrap justify-center items-center space-x-2">
                {userData.ocean_label.map((label, index) => (
                    <div key={index} className="bg-yellow-400 text-white py-1 px-4 rounded">
                        {label}
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:gap-2 lg:gap-4 p-2">
                {userData.ocean_label.map((label, index) => (
                    <ContentBox key={index} title={`${label} - ${oceanInfo.ocean.find(t => t.abbreviation.toLowerCase() === label.toLowerCase())?.trait}`} content={getOceanDescription(label)} />
                ))}
            </div>
        </div>
        );
    };

    const ProfilesPart = () => {
        return (
          <div className="w-full max-w-[1300px] flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/3">
              <DiscProfile />
            </div>
            <div className="w-full md:w-2/3">
              <OceanProfile />
            </div>
          </div>
        );
    };

    const UserInfomationBoard = () => {
        return (
            <div className="flex-grow flex flex-col items-center space-y-8 py-4 px-4 md:px-8">
                {/* First Part */}
                <WelcomeString />
                {/* Second Part */}
                <UserBasicInfo />
                {/* Third Part */}
                <ProfilesPart />
            </div>
        );
    };

    const Footer = () => {
        return (
        <div className='py-4 bg-white flex justify-end shadow-2xl'>
            <div className="mb-3 mt-3">
                <button 
                className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
                onClick={handleNextClick}
                >
                Next
                </button>
            </div>
        </div>
        )
    }


    const handleNextClick = () => {
        // Add your logic here for what should happen when the Next button is clicked
        console.log("Next button clicked");
        // For example, you might want to navigate to the next page or submit the review
        // You can add navigation logic or API calls here
        navigate('/reviewinfo');
    };

    return (
    <div className="flex flex-col min-h-screen w-full bg-gray-100">
        {/* This part is for the header section */}
        <OnboardingHeaderPart />
        {/* This part is for the main/body section */}
        <UserInfomationBoard />
        {/* Footer is placed here to ensure it stays at the bottom */}
        <Footer />
    </div>
  );
};

export default OnboardingReviewUser;
