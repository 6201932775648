// src/App.jsx
import React, { useState } from 'react';
import HeaderMainPage from '../../header/HeaderMainPage';

const Table = ({ emailData, onRowClick }) => {
  return (
    <div className="w-full h-full">
      <h2 className="px-10 text-left text-xl font-bold mb-4 mt-3">Mailbox</h2>
      <table className="w-11/12 bg-white border border-gray-200 text-xs shadow-xl rounded-2xl mx-auto mt-3">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-1 px-2 border-b w-1/4 text-left">Company</th>
            <th className="py-1 px-2 border-b w-1/4 text-left">Email</th>
            <th className="py-1 px-2 border-b w-1/4 text-left">Date</th>
          </tr>
        </thead>
        <tbody>
          {emailData.map((row, index) => (
            <tr key={index} className="hover:bg-blue-200 cursor-pointer" onClick={() => onRowClick(row)}>
              <td className="py-1.5 px-3 border-b-2">{row.company}</td>
              <td className="py-1 px-3 border-b-2">{row.email}</td>
              <td className="py-1 px-3 border-b-2">{row.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const BodyLeft = ({ emailData, onRowClick }) => {
  return (
    <div className="w-1/2 p-4 flex-1">
      <Table emailData={emailData} onRowClick={onRowClick} />
    </div>
  );
};

const BodyRight = ({ selectedEmail }) => {
  return (
    <div className="w-1/2 p-4 bg-white flex flex-col flex-1 mt-10">
      <div className="bg-white flex-1 overflow-auto shadow-lg rounded-2xl">
        <div className="mb-">
          <h3 className="py-2 px-6 bg-gray-200 text-lg font-bold">RE: Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
        </div>
        <div className="py-2 px-6 flex items-center mb-4">
          <div className="w-10 h-10 bg-gray-400 rounded-full mr-4"></div>
          <div>
            <div className="font-bold">{selectedEmail.company} <span className="text-gray-500">&lt;{selectedEmail.email}&gt;</span></div>
            <div className="text-gray-500">to me</div>
          </div>
        </div>
        <div className="py-1 px-6 rounded-lg p-4">
          <div className="px-4 bg-white border border-gray-200 p-4 rounded-xl">
            <p>Lorem ipsum dolor sit amet. Qui eius mollitia et quaerat galisum qui doloremque omnis non quia nihil quo galisum repellendus et incidunt tenetur. Non repellat aspernatur cum animi veniam id facilis tempora qui dicta.</p>
            <br/>
            <p>Eum iure adipisci et minus facilis et tempora ipsam At natus illo vel pariatur praesentium ex natus temporibus nam corporis laborum! Ab temporibus illum aut officia vitae vel iusto aperiam qui voluptates ratione.</p>
            <br/>
            <p>A doloremque accusantium est voluptatem earum cum doloremque quaerat est optio voluptatem est animi laborum cum galisum cumque. Est sint voluptatem ea velit assumenda vel quasi dolores sed voluptatibus doloremque!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Body = ({ emailData }) => {
  const [selectedEmail, setSelectedEmail] = useState(emailData[0]);

  const handleRowClick = (email) => {
    setSelectedEmail(email);
  };

  return (
    <div className="flex flex-1 max-w-[1200px] w-[1000px] h-full">
      <BodyLeft emailData={emailData} onRowClick={handleRowClick} />
      <BodyRight selectedEmail={selectedEmail} />
    </div>
  );
};

const MailboxData = () => {
  const emailData = [
    { id: 1, company: 'Workman', email: 'contact@workman.com', date: '2023-10-01' },
    { id: 2, company: 'Septimus', email: 'info@septimus.com', date: '2023-10-02' },
    { id: 3, company: 'Calzoni', email: 'support@calzoni.com', date: '2023-10-03' },
    { id: 4, company: 'Baptista', email: 'info@baptista.com', date: '2023-10-04' },
    { id: 5, company: 'Stanton', email: 'contact@stanton.edu', date: '2023-10-05' },
    { id: 6, company: 'Vetrovs', email: 'sales@vetrovs.com', date: '2023-10-06' },
    { id: 7, company: 'TechGiant', email: 'info@techgiant.com', date: '2023-10-07' },
    { id: 8, company: 'FinanceWiz', email: 'support@financewiz.com', date: '2023-10-08' },
    { id: 9, company: 'FoodDelights', email: 'contact@fooddelights.com', date: '2023-10-09' },
    { id: 10, company: 'HealthPlus', email: 'info@healthplus.com', date: '2023-10-10' },
    { id: 10, company: 'HealthPlus', email: 'info@healthplus.com', date: '2023-10-01' },
    { id: 11, company: 'EduSmart',  email: 'admin@edusmart.com', date: '2023-10-01' },
    { id: 12, company: 'AutoInnovate',  email: 'sales@autoinnovate.com', date: '2023-10-01' },
    { id: 13, company: 'TechSolutions',  email: 'support@techsolutions.com', date: '2023-10-01' },
    { id: 14, company: 'MoneyMatters',  email: 'info@moneymatters.com', date: '2023-10-01' },
    { id: 15, company: 'GourmetEats',  email: 'orders@gourmeteats.com', date: '2023-10-01' },
    { id: 16, company: 'MediCare',  email: 'patients@medicare.com', date: '2023-10-01' },
    { id: 17, company: 'LearnQuest',  email: 'students@learnquest.com', date: '2023-10-01' },
    { id: 18, company: 'DriveMax',  email: 'service@drivemax.com', date: '2023-10-01' },
    { id: 19, company: 'DataDynamics',  email: 'info@datadynamics.com', date: '2023-10-01' },
    { id: 20, company: 'WealthWise', email: 'clients@wealthwise.com', date: '2023-10-01' },
  ];

  return (

      <div className="min-h-screen flex flex-col bg-white">
        <HeaderMainPage />
        <div className="flex-1 flex justify-center">
          <Body emailData={emailData} />
        </div>
      </div>

  );
};

export default MailboxData;