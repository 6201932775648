import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {OnboardingHeaderPart, LargeImagePlaceholder} from '../../header/OnboardingHeader';
import video2 from '../../assets/page2.mp4'

const OnboardingInputData = () => {
  // const [showPopup, setShowPopup] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [nickname, setNickname] = useState('');
  const [companyType, setCompanyType] = useState('Partnership');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const navigate = useNavigate();
  const fetch_user_api = process.env.REACT_APP_FETCH_USER_DATA_API
  const upload_data_api = process.env.REACT_APP_UPLOAD_DATA

  // New function to fetch user data from API
  const fetchUserDataFromAPI = async (access_token) => {
    try {
      const response = await fetch(fetch_user_api, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': access_token,
          },

      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      // localStorage.setItem('data_user', data);
      console.log(data.user_info)
      // console.log(response)
      return data.user_info;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Assume we have the user's email from somewhere (e.g., context, props, or localStorage)
      // const userEmail = localStorage.getItem('userEmail')
      const access_token = localStorage.getItem('access_token')
      // Fetch data from API
      const userData = await fetchUserDataFromAPI(access_token);
      
      if (userData) {
        // If API fetch is successful, use the data
        console.log("API fetch is successful")
        localStorage.setItem('nickname', userData.nick_name);
        localStorage.setItem('companyType', userData.company_type);
        localStorage.setItem('linkedinUrl', userData.linkedin_address);
        
        const savedNickname = localStorage.getItem('nickname');
        const savedCompanyType = localStorage.getItem('companyType');
        const savedLinkedinUrl = localStorage.getItem('linkedinUrl');

        if (savedNickname) setNickname(savedNickname);
        if (savedCompanyType) setCompanyType(savedCompanyType);
        if (savedLinkedinUrl) setLinkedinUrl(savedLinkedinUrl);

      } else {
        // If API fetch fails, load from localStorage
        console.log("API fetch is fail")
        const savedNickname = localStorage.getItem('nickname');
        const savedCompanyType = localStorage.getItem('companyType');
        const savedLinkedinUrl = localStorage.getItem('linkedinUrl');

        if (savedNickname) setNickname(savedNickname);
        if (savedCompanyType) setCompanyType(savedCompanyType);
        if (savedLinkedinUrl) setLinkedinUrl(savedLinkedinUrl);
      }
    };

    fetchData();
    // localStorage.removeItem('userType');
  }, []);

  useEffect(() => {
    // Save data to localStorage whenever it changes
    localStorage.setItem('nickname', nickname);
    localStorage.setItem('companyType', companyType);
    localStorage.setItem('linkedinUrl', linkedinUrl);
  }, [nickname, companyType, linkedinUrl]);

  const handlingUploadData = async () => {
    try {
      const access_token = localStorage.getItem('access_token');
      const response = await fetch(upload_data_api, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': access_token,
          },
          body: JSON.stringify({
            user_type: localStorage.getItem('userType'),
            nick_name: nickname,
            company_type: companyType,
            linkedin_address: linkedinUrl,
            billing_plan: "None",
          }),
      });

      if (response.ok) {
          const data = await response.json();
          console.log(data.message);
          // navigate('/campinitselect');
      } else {
          Swal.fire({
            title: 'Error!',
            text: 'Please check again the information or reload the page',
            icon: 'error',
            confirmButtonText: 'Back'
          });
      }
    } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred. Please try again.',
          icon: 'error',
          confirmButtonText: 'Back'
        });
    }
  };

  const handlingFetchLinkedIn = async (apikey, id) => {
    try {
      const response = await fetch(`https://api.humantic.ai/v1/user-profile?apikey=${apikey}&id=${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching LinkedIn data:', error);
      return { data: { status: 'error' } };
    }
  };

  const handlingCreateLinkedIn = async (apikey, id, firstname, lastname) => {
    try {
      const response = await fetch(`https://api.humantic.ai/v1/user-profile/create?apikey=${apikey}&id=${id}&firstname=${firstname}&lastname=${lastname}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating LinkedIn profile:', error);
      return { status: 'error' };
    }
  };

  const handlingLinkedIn = async () => {
    try {
      const apikey = process.env.REACT_APP_HUMANTICAI_KEY;
      const fetchResult = await handlingFetchLinkedIn(apikey, linkedinUrl);

      if (fetchResult.data && fetchResult.data.status === 'error') {
        console.log("linkedin fetch fail, create new acc then fetch again")
        const firstname = nickname.split(' ')[0];
        const lastname = nickname.split(' ').slice(1).join(' ') || nickname;
        const createResult = await handlingCreateLinkedIn(apikey, linkedinUrl, firstname, lastname);

        if (createResult.status === '200') {
          const newFetchResult = await handlingFetchLinkedIn(apikey, linkedinUrl);
          if (newFetchResult && newFetchResult.status === '200') {
            localStorage.setItem('linkedInResult', JSON.stringify(newFetchResult.results));
          }
          console.log("linkedin fetch success")
        }
      } else if (fetchResult && fetchResult.status === '200') {
        console.log("linkedin fetch success")
        localStorage.setItem('linkedInResult', JSON.stringify(fetchResult.results));
      }
    } catch (error) {
      console.error('Error handling LinkedIn:', error);
      // Swal.fire({
      //   title: 'Error!',
      //   text: 'An error occurred while processing LinkedIn data. Please try again.',
      //   icon: 'error',
      //   confirmButtonText: 'Back'
      // });
    }
  };

  const handleContinue = async () => {
    if (currentTab === 0) {
      setCurrentTab(1);
    } else if ((!nickname || !linkedinUrl)||(nickname.trim() === "" || linkedinUrl.trim() === "")) {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill in the blank field',
        icon: 'error',
        confirmButtonText: 'Back'
      });
    } else {
      console.log('upload data')
      await handlingUploadData();
      console.log('get linkedin data')
      await handlingLinkedIn();

      navigate('/campinitselect');
    }
  };

  const handleBack = () => {
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    } else {
      navigate('/onboarding-select');
    }
  };

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  const Header = ({ className, text }) => <div className={className}>{text}</div>;

  // const Button = ({ className, onClick, text }) => <button className={className} onClick={onClick}>{text}</button>;

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* <div className="w-full bg-white pt-5">
        <Header className="w-40 h-20 bg-gray-200 flex items-center justify-center text-xs ml-[10%]" text="Small Image Placeholder" />
      </div> */}
      <OnboardingHeaderPart />

      <div className="flex justify-center items-start flex-grow pt-10 overflow-auto bg-gray-100">
        <div className="flex w-[1200px] min-h-[600px]">
          {/* Adjusted height and added padding to the bottom of the left section */}
          {/* <div className="w-[600px] h-[600px] bg-gray-200 flex items-center justify-center ml-16 mr-4 pb-4">
            <span className="text-gray-500">Large Image Placeholder</span>
          </div> */}
          <LargeImagePlaceholder videomain={video2}/>

          <div className="w-[600px] h-[600px] bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full h-full">
              {/* Tab Bar */}
              <div className="flex justify-start mb-4 space-x-2 mt-3">
                <div
                  className={`w-1/4 h-2.5 rounded-full ${currentTab === 0 ? 'bg-orange-300 dark:bg-red-500' : 'bg-gray-200 dark:bg-gray-700'}`}
                  onClick={() => setCurrentTab(0)}
                ></div>
                <div
                  className={`w-1/4 h-2.5 rounded-full ${currentTab === 1 ? 'bg-orange-300 dark:bg-red-500' : 'bg-gray-200 dark:bg-gray-700'}`}
                  onClick={() => setCurrentTab(1)}
                ></div>
              </div>

              {/* Heading moved outside of tab content */}
              <h2 className="text-[#223F9E] font-bold text-2xl mb-10 mt-5">
                Help us to personalize your experience better on <strong> Intuicon.ai </strong>
              </h2>

              {/* Tab Content with simple CSS transition */}
              <div className="relative h-[250px] overflow-hidden">
                <div
                  className="absolute w-full transition-transform duration-300 ease-in-out"
                  style={{ transform: `translateX(${currentTab === 0 ? '0%' : '-100%'})` }}
                >
                  <div className="space-y-6">
                    <div>
                      <label className="block text-gray-700 mb-2">Nick name</label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-2">What kind of company do you work for?</label>
                      <select
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        value={companyType}
                        onChange={(e) => setCompanyType(e.target.value)}
                      >
                        <option value="Partnership">Partnership</option>
                        <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
                        <option value="Private Limited Company (Ltd)">Private Limited Company (Ltd)</option>
                        <option value="Public Limited Company (PLC)">Public Limited Company (PLC)</option>
                        <option value="Umbrella Company">Umbrella Company</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="absolute w-full transition-transform duration-300 ease-in-out"
                  style={{ transform: `translateX(${currentTab === 1 ? '0%' : '100%'})` }}
                >
                  <div className="space-y-6">
                    <div>
                      <label className="block text-gray-700 mb-2">Paste your Linkedin address</label>
                      <input
                        type="url"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        value={linkedinUrl}
                        onChange={(e) => setLinkedinUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-left space-x-5">
                <button
                  className="w-[100px] h-[40px] bg-gray-200 text-Black font-bold rounded-full hover:bg-gray-300 transition-colors"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="w-[100px] h-[40px] bg-[#223F9E] text-white font-bold rounded-full hover:bg-blue-700 transition-colors"
                  onClick={handleContinue}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/* {showPopup && (
        // <div className="fixed inset-0 flex items-center justify-center z-50">
        //   <div className="absolute inset-0 bg-black opacity-50"></div>
        //   <div className="bg-white border border-gray-300 rounded-lg p-6 shadow-lg w-[400px] z-10 relative">
        //     <p className="text-lg font-semibold mb-4">Attention</p>
        //     <p className="text-base mb-4">Please choose one option to optimize AI.</p>
        //     <div className="flex justify-center">
        //       <Button onClick={closePopup}
        //         className="px-4 py-2 bg-[#223F9E] text-white font-bold rounded-full hover:bg-blue-700 transition-colors"
        //         text="Close" />
        //     </div>
        //   </div>
        // </div>
        Swal.fire({
          title: 'Error!',
          text: 'Please fill in the blank field',
          icon: 'error',
          confirmButtonText: 'Back'
        })
      )} */}
    </div>
  );
};

export default OnboardingInputData;
