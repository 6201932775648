import React from 'react';
import Logo from '../assets/Logo.png';
import manimage from '../assets/image1.png';

import VideoPlayer from '../component/onboardingPage/VideoPlayer';
import videonothing from '../assets/saynothing.mp4'

const LargeImagePlaceholder = ({videomain}) => {
    return (
      <div className="w-[600px] h-[600px] bg-[#223F90] flex items-center justify-center ml-16 mr-4 pb-4 rounded-md overflow-hidden">
        {/* <img 
          src={manimage} 
          alt="Man" 
          className="w-full h-full object-cover transform scale-105"
        /> */}
        <VideoPlayer video1Url={videomain} sayNothingUrl={videonothing} />
      </div>
    );
};

const OnboardingHeaderPart = () => {
  return (
    <>
      {/* Full-width header box containing both image placeholders */}
      <div className="w-full bg-gray-100 pt-5 flex items-start">
        <div className="w-40 h-20 bg-transparent flex items-center justify-center text-xs ml-[10%]">
          <img src={Logo} alt="Logo" className="max-w-full max-h-full object-contain" />
        </div>
      </div>
    </>
  );
};

// Export LargeImagePlaceholder as a named export
export { OnboardingHeaderPart, LargeImagePlaceholder };
