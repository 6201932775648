import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';

const NewCampaign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');
  // const [selectedData, setSelectedData] = useState('campaign 1');
  const [description, setDescription] = useState('');
  const [mustHaveInfo, setMustHaveInfo] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [targetIndustry, setTargetIndustry] = useState(() => {
    const savedTargetIndustry = localStorage.getItem('targetIndustry');
    return savedTargetIndustry ? JSON.parse(savedTargetIndustry) : [];
  });
  const [targetIndustry2, setTargetIndustry2] = useState('');

  const [industryInput, setIndustryInput] = useState('');
  const [targetRegion, setTargetRegion] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isConfirmChecked, setConfirmChecked] = useState(false);

  useEffect(() => {
    // Load saved data from localStorage
    const savedName = localStorage.getItem('campaign_name');
    // const savedSelectedData = localStorage.getItem('campaignSelectedData');
    const savedDescription = localStorage.getItem('core_service');
    const savedMustHaveInfo = localStorage.getItem('campaignMustHaveInfo');
    const savedOutputFormat = localStorage.getItem('campaignOutputFormat');
    const savedTargetIndustry = localStorage.getItem('target_audience');

    if (savedName) setName(savedName);
    // if (savedSelectedData) setSelectedData(savedSelectedData);
    if (savedDescription) setDescription(savedDescription);
    if (savedMustHaveInfo) setMustHaveInfo(savedMustHaveInfo);
    if (savedOutputFormat) setOutputFormat(savedOutputFormat);
    if (savedTargetIndustry) setTargetIndustry2(savedTargetIndustry);
  }, []);

  useEffect(() => {
    // Save targetIndustry to localStorage whenever it changes
    localStorage.setItem('targetIndustry', JSON.stringify(targetIndustry));
  }, [targetIndustry]);
  
  const handleExit = () => {
    navigate('/dashboard');
  };

  const handleTargetIndustryKeyDown = (e) => {
    if (e.key === 'Enter' && industryInput.trim() !== '') {
      setTargetIndustry([...targetIndustry, industryInput.trim()]);
      setIndustryInput('');
    }
  };

  const removeTargetIndustry = (indexToRemove) => {
    setTargetIndustry(targetIndustry.filter((_, index) => index !== indexToRemove));
  };

  const handleNext = () => {
    if (mustHaveInfo.trim() === '') {
      Swal.fire({
        title: 'Missing Information',
        text: 'Please provide the must-have information before proceeding.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } else {
      setShowPopup(true);
    }
  };

  const saveStateToLocalStorage = (state) => {
    localStorage.setItem('campaignState', state);
  };

  const handleNotificationConfirm = () => {
    saveStateToLocalStorage('Confirm');
    navigate('/email-status');
  };

  const handleNotificationCancel = () => {
    saveStateToLocalStorage('Cancel');
    navigate('/email-status');
  };
  
  const handleCheckboxChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  // Update state setters to also save to localStorage
  const updateName = (value) => {
    setName(value);
    localStorage.setItem('campaignName', value);
  };

  // const updateSelectedData = (value) => {
  //   setSelectedData(value);
  //   localStorage.setItem('campaignSelectedData', value);
  // };

  const updateDescription = (value) => {
    setDescription(value);
    localStorage.setItem('campaignDescription', value);
  };

  const updateMustHaveInfo = (value) => {
    setMustHaveInfo(value);
    localStorage.setItem('campaignMustHaveInfo', value);
  };

  const updateTargetRegion = (value) => {
    setTargetRegion(value);
    localStorage.setItem('campaignMustHaveInfo', value);
  };

  const updateOutputFormat = (value) => {
    setOutputFormat(value);
    localStorage.setItem('campaignOutputFormat', value);
  };

  const updateTargetIndustry2 = (value) => {
    setOutputFormat(value);
    localStorage.setItem('target_audience', value);
  };
  
  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      
      <HeaderMainPage />

      <div className="w-4/6 h-full items-start rounded-lg shadow-md my-5 mt-[30px] rounded-md">
        <div className="w-full bg-gray-100 h-[50px] border rounded-md flex items-center justify-center">
          <div className="w-full flex justify-between items-center pl-8 pr-8">
            <h2 className="text-xl font-bold">New Campaign</h2>
            <button onClick={handleExit} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
          </div>
        </div>

        <form className="mt-4 mr-8 ml-8 mb-4 space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-0">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => updateName(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
              placeholder='Input your campaign name'
            />
          </div>
          {/* <div>
            <label htmlFor="data" className="block text-sm font-medium text-gray-700 mb-1">Choose data from your library:</label>
            <select
              id="data"
              value={selectedData}
              onChange={(e) => updateSelectedData(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
            >
              <option value="campaign 1">campaign 1</option>
              <option value="campaign 2">campaign 2</option>
              <option value="campaign 3">campaign 3</option>
            </select>
          </div> */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none bg-gray-100"
              placeholder='Give some description'
            />
          </div>
          <div>
            <label htmlFor="mustHaveInfo" className="block text-sm font-medium text-gray-700 mb-1">Must have information:</label>
            <textarea
              id="mustHaveInfo"
              value={mustHaveInfo}
              onChange={(e) => updateMustHaveInfo(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none bg-gray-100" 
            />
          </div>

          <div>
            <label htmlFor="outputFormat" className="block text-sm font-medium text-gray-700 mb-1">Desired output format:</label>
            <input
              type="text"
              id="outputFormat"
              value={outputFormat}
              onChange={(e) => updateOutputFormat(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
            />
          </div>
          <div>
            <label htmlFor="targetIndustry" className="block text-sm font-medium text-gray-700 mb-1">Target Industry:</label>
            <input
              type="text"
              id="targetIndustry"
              value={targetIndustry2}
              onChange={(e) => updateTargetIndustry2(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
            />
          </div>

          {/* <div>
            <label htmlFor="targetRegion" className="block text-sm font-medium text-gray-700 mb-1">Target Region:</label>
            <div className="flex flex-wrap gap-2 p-3 border border-gray-300 rounded-md focus-within:ring-2 focus-within:ring-blue-500 bg-gray-100">
              {targetRegion.map((region, index) => (
                <span key={index} className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm flex items-center">
                  {region}
                  <button type="button" onClick={() => removeTargetRegion(index)} className="ml-1 text-white hover:text-gray-200 ">×</button>
                </span>
              ))}
              <input
                type="text"
                id="targetRegion"
                value={regionInput}
                onChange={(e) => setRegionInput(e.target.value)}
                onKeyDown={handleTargetRegionKeyDown}
                className="flex-grow outline-none bg-gray-100"
                placeholder="Add a target region and press enter"
              />
            </div>
          </div> */}

          {/* <div>
            <label htmlFor="targetIndustry" className="block text-sm font-medium text-gray-700 mb-1">Target industry:</label>
            <div className="flex flex-wrap gap-2 p-3 border border-gray-300 rounded-md focus-within:ring-2 focus-within:ring-blue-500 bg-gray-100">
              {targetIndustry.map((industry, index) => (
                <span key={index} className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm flex items-center">
                  {industry}
                  <button type="button" onClick={() => removeTargetIndustry(index)} className="ml-1 text-white hover:text-gray-200 ">×</button>
                </span>
              ))}
              <input
                type="text"
                id="targetIndustry"
                value={industryInput}
                onChange={(e) => setIndustryInput(e.target.value)}
                onKeyDown={handleTargetIndustryKeyDown}
                className="flex-grow outline-none bg-gray-100"
                placeholder="Add a target industry and press enter"
              />
            </div>
          </div> */}

          <div>
            <label htmlFor="targetRegion" className="block text-sm font-medium text-gray-700 mb-1">Target Region:</label>
            <input
              type="text"
              id="targetRegion"
              value={targetRegion}
              onChange={(e) => updateTargetRegion(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
            />
          </div>
        </form>

        <div className="flex justify-center mt-1 mb-4">
          <button 
            className="bg-[#223F9E] text-white py-2 px-8 rounded-full hover:bg-blue-700 transition-colors duration-200"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-lg w-3/5 max-w-2xl mt-1 max-h-[800px]">
              <div className="w-full bg-gray-50 h-[70px] border rounded-md flex items-center justify-center">
                <div className="w-full flex justify-between items-center pl-8 pr-8">
                  <h2 className="text-2xl font-bold">Notification</h2>
                  <button onClick={handleExit} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
                </div>
              </div>
              <div>
                <hr className="border-t-1 border-gray-100 mb-4 w-full" />
              </div>
              <div className="space-y-7 overflow-y-auto flex items-center justify-center flex-col mb-8">
                <p className="text-gray-700 text-left ml-8 mr-8">
                Thank you for sharing your valuable information. Your input proves crucial in refining our understanding of your target audience. Following is the detailed approach we would take to crafting and delivering marketing emails to them. 
                </p>
          
                <div className="flex justify-center">
                  <div className="bg-gray-100 p-5 rounded-lg">
                    <div className="space-y-4 text-left ml-3 mr-3">
                      <label className="flex items-center">
                        <span className="font-bold">Step 1:</span> <input type="checkbox" className="mx-2" checked /><p>Get your information</p>
                      </label>
                      <label className="flex items-center">
                        <span className="font-bold text-gray-400">Step 2:</span> <input type="checkbox" className="mx-2" checked/><p className="text-gray-400">Gather advance users' information from Linkedin</p>
                      </label>
                      <div className="flex items-center">
                        <span className="mr-1 font-bold">Step 3:</span><p>Generate email content</p>
                      </div>
                      <div className="flex items-center">
                        <span className="mr-1 font-bold">Step 4:</span><p>Send out emails</p>
                      </div>
                    </div>
                  </div>
                </div>
          
                <div className="flex items-center text-left ml-8 mr-8">
                  <input type="checkbox" className="mr-2" checked={isConfirmChecked} onChange={handleCheckboxChange}/>
                  <span className="text-gray-700">
                    I've read and agreed all the steps that Intuicon will take to help
                    me send emails.
                  </span>
                </div>
          
                <div className="flex justify-center space-x-6">
                  <button
                    className={`px-4 py-2 rounded-full ${
                      isConfirmChecked
                        ? 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                        : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    }`}
                    disabled={!isConfirmChecked}
                    onClick={handleNotificationCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className={`px-4 py-2 rounded-full ${
                      isConfirmChecked
                        ? 'bg-blue-500 hover:bg-blue-600 text-white'
                        : 'bg-blue-200 text-white cursor-not-allowed'
                    }`}
                    disabled={!isConfirmChecked}
                    onClick={handleNotificationConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default NewCampaign;
