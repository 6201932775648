// VideoPlayer.js
import React, { useState, useRef, useEffect } from 'react';

const VideoPlayer = ({ video1Url, sayNothingUrl }) => {
  const [currentVideo, setCurrentVideo] = useState(video1Url);
  const [isVideo1, setIsVideo1] = useState(true);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);

  const zoomFactor = 1.1;

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          videoRef.current.load();
          await videoRef.current.play();
          console.log("Video started playing:", currentVideo);
        } catch (e) {
          console.error("Error playing video:", e);
        //   setError("Error playing video. Click to try again.");
        }
      }
    };

    playVideo();
  }, [currentVideo]);

  const handleVideoEnd = () => {
    console.log("Video ended");
    if (isVideo1) {
      setCurrentVideo(sayNothingUrl);
      setIsVideo1(false);
    }
  };

  const handleRepeat = () => {
    console.log("Repeat button clicked");
    setCurrentVideo(video1Url);
    setIsVideo1(true);
    setError(null);
  };

  const handleCanPlay = () => {
    console.log("Video can play");
  };

  const handleError = (e) => {
    console.error("Video error:", e);
    setError("Error loading video. Please check the video source.");
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-5">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <video
        ref={videoRef}
        className="w-full h-auto"
        onEnded={handleVideoEnd}
        onCanPlay={handleCanPlay}
        onError={handleError}
        style={{
            transform: `scale(${zoomFactor})`,
            transformOrigin: 'center center'
        }}
        autoPlay
        playsInline
        // controls // Add controls for debugging
        loop={!isVideo1}
      >
        <source src={currentVideo} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <button
        className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full hover:bg-opacity-75 transition-colors duration-200"
        onClick={handleRepeat}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
      </button>
      {/* <p className="mt-2">Current video: {currentVideo}</p> */}
    </div>
  );
};

export default VideoPlayer;